import "@fontsource/pt-serif/400-italic.css";
import styled from "styled-components";

const Title = styled.h3`
  width: 100%;
  height: auto;
  padding-bottom: 5px;
  border-bottom-style: dotted;
  border-width: 1px;
  border-color: #cccccc;
  font-size: 1.9rem;
  font-style: italic;
  color: #497f63;
  margin-bottom: 10px;
`;

export default Title;
