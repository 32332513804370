import React from "react";
import { Helmet } from "react-helmet";
import { Container as BsContainer, Row, Col } from "react-bootstrap";
import styled, { createGlobalStyle } from "styled-components";
import tree_top from "../images/tree_top.png";
import tree_bottom from "../images/tree_bottom.png";
import background from "../images/background.png";
import Header from "./Header";
import "@fontsource/pt-serif/400.css";
import "@fontsource/pt-serif/400-italic.css";
import master_logo from "../images/master_logo.png";

const GlobalStyle = createGlobalStyle`
  body {
    background: url(${background});
    font-family: "Times New Roman";
    font-weight: 400;
  }
`;

const Container = styled(BsContainer)`
  background: url(${tree_top}) no-repeat top center;
  @media (max-width: 576px) {
    background-size: 80%;
  }
  @media (min-width: 1400px) {
    max-width: 1210px;
  }
`;

const Footer = styled.footer`
  width: 100%;
  margin-top: 1.25rem;
`;

const LeftFooter = styled(Col)`
  margin-bottom: -1.25rem;
  padding: 0;
`;

const FooterBgContainer = styled.div`
  width: 100%;
  padding-top: 80%;
  background: url(${tree_bottom}) no-repeat top;
  background-size: 80%;
`;

const RightFooter = styled(Col)`
  border-top-style: dotted;
  border-color: #cccccc;
  border-width: 1px;
  margin-top: 19px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items flex-end;
`;

const Credits = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #497f63;
  &:hover {
    color: #497f63;
  }
`;

const CreditsText = styled.span`
  margin-right: 1rem;
  font-size: 1.2rem;
`;
const Layout = ({ pageTitle, children }) => {
  const nav_items = [
    {
      loc: "/",
      desc: "Strona główna",
    },
    {
      loc: "/gallery",
      desc: "Galeria",
    },
    {
      loc: "/availability",
      desc: "Wolne miejsca",
    },
    {
      loc: "/contact",
      desc: "Kontakt",
    },
    {
      loc: "/map",
      desc: "Dojazd",
    },
    {
      loc: "/surroundings",
      desc: "Rowy i okolice",
    },
  ];
  return (
    <>
      <GlobalStyle />
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content />
        <title>{pageTitle}</title>
      </Helmet>

      <Container>
        <Header items={nav_items} />
        <Row noGutters={true}>{children}</Row>
        <Footer>
          <Row noGutters={true}>
            <LeftFooter xs={4} md={3} lg={2}>
              <FooterBgContainer />
            </LeftFooter>
            <RightFooter>
              <Credits href="http://masterproject.pl/">
                <CreditsText>Szata graficzna</CreditsText>
                <img src={master_logo} />
              </Credits>
            </RightFooter>
          </Row>
        </Footer>
      </Container>
    </>
  );
};

export default Layout;
