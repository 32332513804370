import React from "react";
import styled from "styled-components";
import carousel1 from "../images/1.png";
import carousel2 from "../images/2.png";
import carousel3 from "../images/3.png";
import { Carousel as BsCarousel } from "react-bootstrap";

const StyledCarousel = styled(BsCarousel)`
  display: block;
  @media (max-width: 576px) {
  }
`;

const CarouselImage = styled.img`
  width: 100%;
  height: auto;
`;

const Carousel = ({}) => {
  return (
    <StyledCarousel>
      <BsCarousel.Item>
        <CarouselImage src={carousel1} alt="" />
      </BsCarousel.Item>
      <BsCarousel.Item>
        <CarouselImage src={carousel2} alt="" />
      </BsCarousel.Item>
      <BsCarousel.Item>
        <CarouselImage src={carousel3} alt="" />
      </BsCarousel.Item>
    </StyledCarousel>
  );
};

export default Carousel;
