import React from "react";
import PropTypes from "prop-types";
import logo from "../images/logo.png";
import Navigation from "./Navigation";
import Carousel from "./Carousel";
import { Link } from "gatsby";
import { Navbar, Nav, Image } from "react-bootstrap";
import styled from "styled-components";
import menu_separator from "../images/menu_separator.png";

const Container = styled.header`
  margin-bottom: 2.5rem;
  padding-top: 1rem;
`;

const LogoLink = styled(Link)`
  height: auto;
  display: block;
  color: #4c7c64;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  &:hover {
    color: #4c7c64;
    background: none;
    border: none;
    text-decoration: none;
  }
  @media (min-width: 576px) {
    width: 7ch;
    font-size: 2.2rem;
    line-height: 2.2rem;
  }
`;

const StyledNav = styled(Nav)`
  border: none;
  width: 100%;
  margin: 0;
  padding: 0 1rem 0 1rem;
`;

const StyledNavbar = styled(Navbar)`
  background: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  @media (min-width: 576px) {
    flex-direction: column;
  }
`;

const StyledLink = styled(Link)`
  font-size: 1.125em;
  color: #777777 !important;
  border: none;
  padding: 0.625rem 1rem 0.625rem 1rem !important;

  &:hover {
    color: #497f63 !important;
    background: none;
    border: none;
  }

  @media (min-width: 576px) {
  }
`;

const StyledBrand = styled(Navbar.Brand)`
  display: flex;
  flex-shrink: 1;
  justify-content: center;

  @media (min-width: 576px) {
    border-bottom-style: solid;
    padding-bottom: 1rem;
    border-image: url(${menu_separator}) 30 30 repeat !important;
    border-width: 0 0 1.9rem 0;
    flex-basis: 100%;
    width: 100%;
    flex-direction: row;
    flex-shrink: 0;
  }
`;

const StyledCollapse = styled(Navbar.Collapse)`
  flex-basis: 100%;
  flex-shrink: 0;
`;

/*
      <LogoLink to="/">
        <Logo src={logo} />
      </LogoLink>
*/

const Header = ({ items }) => {
  return (
    <Container>
      <StyledNavbar expand="sm">
        <Navbar.Toggle aria-controls="navbar-nav" />
        <StyledBrand as="div">
          <LogoLink to="/">Willa Pod Dębami</LogoLink>
        </StyledBrand>
        <StyledCollapse id="navbar-nav" className="w-100">
          <StyledNav justify>
            {items.map(({ desc, loc }) => {
              return (
                <Nav.Link key={loc} as={StyledLink} to={loc}>
                  {desc}
                </Nav.Link>
              );
            })}
          </StyledNav>
        </StyledCollapse>
      </StyledNavbar>
      <Carousel />
    </Container>
  );
};

export default Header;
