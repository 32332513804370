import React from "react";
import PropTypes from "prop-types";
import { Navbar, Nav } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "gatsby";
import menu_separator from "../images/menu_separator.png";

const StyledNav = styled(Nav)`
  border: none;
  width: 100%;
  margin: 0;
`;

const StyledNavbar = styled(Navbar)`
  border-top-style: solid;
  border-image: url(${menu_separator}) 30 30 repeat !important;
  border-width: 1rem 1rem 0 1rem;
  @media (min-width: 576px) {
    border-width: 1.9rem 1.9rem 0 1.9rem;
  }
  background: none;
  box-shadow: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
`;

const StyledLink = styled(Link)`
  font-size: 1em;
  color: #777777 !important;
  border: none;
  padding: 10px 15px 10px 15px !important;
  width: 1%;

  &:hover {
    color: #497f63 !important;
    background: none;
    border: none;
  }
`;

const Navigation = ({ items }) => {
  return (
    <StyledNavbar expand="sm">
      <Navbar.Toggle aria-controls="navbar-nav" />
      <Navbar.Collapse id="navbar-nav">
        <StyledNav justify>
          {items.map(({ desc, loc }) => {
            return (
              <Nav.Link key={loc} as={StyledLink} to={loc}>
                {desc}
              </Nav.Link>
            );
          })}
        </StyledNav>
      </Navbar.Collapse>
    </StyledNavbar>
  );
};

export default Navigation;
